import React from "react";
import { Link } from "gatsby";
import { Location } from "@reach/router";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import MarTech from "../../components/MarTech";

function APIDev(props) {
  return (
    <Layout>
      <Location>
        {({ location }) => (
          <SEO
            title="API Development"
            pathname={location.pathname}
            keywords={[`gortek`, `app`, `startup`, `business`]}
          />
        )}
      </Location>

      <MarTech />

      <h2 className="text-primary">API Development</h2>
      <p className="mt-4">
        If your web app relies on heavy duty processing power you'll need to
        create an API for it to communicate with.
      </p>
      <p className="mt-4">
        Sometimes, existing businesses need to creat custom APIs to integrate
        multiple systems together.
      </p>

      <p className="mt-4">
        From ETL processing, IoT back-end development, "Big Data" stream
        processing, to more advanced techniques like Named Entity Extraction and
        Resoultion, we have the experience you need.
      </p>

      <p className="mt-4">
        Gortek can plan and execute whatever data centric development you need.
      </p>

      <p className="mt-4">
        These matters are always more nuanced first imagined, and the devil is
        in the details. Drop us a line to discuss your project needs.{" "}
        <span className="text-primary">hello@gortek.com</span>.
      </p>

      <div className="h-16" />
    </Layout>
  );
}

export default APIDev;
